import { useMutation } from "@tanstack/react-query";
import apiClient from "api/api-client";
import { AxiosResponse } from "axios";
import { ApiError } from "types/api";

export type ImpersonateResponse = {
  token: string;
}

export const useImpersonateSpecialist = () => {
  return useMutation<AxiosResponse<ImpersonateResponse | ApiError>, ApiError, string>({
    mutationFn: async (personalCode: string) => await apiClient.post<ImpersonateResponse>(`/admin/impersonate/specialist-user/${personalCode}`)
  });
};
