import DescriptionIcon from "@mui/icons-material/Description";
import { useDictionary } from "hooks/use-dictionary";

interface EmptyStateProps {
  title?: string;
  text?: string;
}

const EmptyState = ({ title, text }: EmptyStateProps) => {
  const dict = useDictionary();
  return (
    <div className="flex flex-col items-center justify-center w-full text-center bg-white py-2">
      <DescriptionIcon style={{ width: 40, height: 40 }} className="mb-3 text-gray-600" aria-hidden="false" />
      <div className="font-semibold mb-1 text-base">{title ? title : dict.common.noTitle}</div>
      <div className="text-sm max-w-[300px] mx-auto">{text ? text : dict.common.noData}</div>
    </div>
  );
};
export default EmptyState;
