import { createTheme } from "@mui/material/styles";
import { COLORS } from "constants/colors";
import { colord } from "colord";
import Info from "@mui/icons-material/Info";
import Cancel from "@mui/icons-material/Cancel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    additional: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
      dark: colord(COLORS.primary).darken(0.04).toHex(),
      contrastText: "#ffffff",
    },
    error: {
      main: COLORS.error,
    },
    info: {
      main: COLORS.info,
    },
    text: {
      primary: COLORS.text,
    },
    action: {
      disabledBackground: "#4B5563",
      disabled: "#4B5563",
    },
  },
  typography: {
    fontFamily: "Inter, Arial, sans-serif",
    h1: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "18px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "16px",
      fontWeight: 600,
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    body3: {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 44,
          borderRadius: 2,
          fontWeight: 400,
          fontSize: "16px",
          textTransform: "unset",
          boxShadow: "unset",
        },
      },
      variants: [
        {
          props: { variant: "additional", color: "primary" },
          style: {
            backgroundColor: "#F4F6F8",
            color: COLORS.primary,
            "&:hover": {
              backgroundColor: "#E1E3E5",
            },
          },
        },
        {
          props: { variant: "additional", color: "error" },
          style: {
            backgroundColor: colord(COLORS.error).alpha(0.08).toHex(),
            color: COLORS.error,
            "&:hover": {
              backgroundColor: colord(COLORS.error).alpha(0.15).toHex(),
            },
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.Mui-error .MuiCheckbox-root svg": {
            color: COLORS.error,
          },
          "&.Mui-disabled": {
            color: "#6c757d",
          },
          "&.Mui-disabled .MuiFormControlLabel-label": {
            color: "#6c757d",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1.1,
          "&.Mui-disabled": {
            color: "#6c757d",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: 47,
          "&.Mui-disabled": {
            color: "#6c757d",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& input": {
            padding: "11.5px 14px",
          },
          "&.Mui-disabled": {
            color: "#6c757d",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-input": {
            padding: "3px 4px !important",
          },
          "&.MuiOutlinedInput-root": {
            minHeight: 47,
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: COLORS.border,
              transition: "border-color 0.3s ease",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colord(COLORS.border).darken(0.2).toHex(),
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: COLORS.primary,
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: COLORS.error,
            },
          },
          "&.MuiInputBase-multiline": {
            height: "auto",
          },
        },
        input: {
          padding: "11.5px 14px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "11.5px 14px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px 24px",
          height: 72,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "transparent",
          "&.Mui-expanded": {
            minHeight: "auto",
            borderColor: COLORS.border,
          },
          "&.Mui-expanded .MuiAccordionSummary-content": {
            margin: 0,
          },
          "&.Mui-expanded svg": {
            color: COLORS.primary,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":before": {
            display: "none",
          },
          "&:last-child": {
            borderBottom: `1px solid ${COLORS.border}`,
          },
          boxShadow: "none",
          borderLeft: `1px solid ${COLORS.border}`,
          borderRight: `1px solid ${COLORS.border}`,
          borderTop: `1px solid ${COLORS.border}`,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: {
            success: colord(COLORS.primary).alpha(0.1).toHex(),
            warning: colord(COLORS.star).alpha(0.1).toHex(),
            error: colord(COLORS.error).alpha(0.1).toHex(),
            info: colord(COLORS.info).alpha(0.1).toHex(),
          }[ownerState.severity ?? "info"],
        }),
      },
      defaultProps: {
        iconMapping: {
          info: (
            <div className="w-6 h-6 rounded-full bg-theme-info text-white flex">
              <Info fontSize="inherit" className="m-auto !w-4 !h-4" />
            </div>
          ),
          error: <Cancel className="!w-6 !h-6" />,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-error svg": {
            color: COLORS.error,
          },
          "&.Mui-checked svg": {
            color: COLORS.primary,
          },
          "& svg": {
            color: COLORS.border,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          "& button": {
            padding: 4,
          },
        },
        root: {
          border: "none",
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <NavigateNextIcon fontSize="small" color="inherit" />,
      },
      styleOverrides: {
        ol: {
          "& li:first-of-type a": {
            color: COLORS.primary,
            textDecoration: "underline",
          },
          "& li:not(:first-of-type):not(:last-of-type) a": {
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
        separator: {
          margin: "0 4px",
          color: "black",
        },
      },
    },
  },
});

export default theme;
