import apiClient from "api/api-client";
import { API_URL, REFRESH_TOKEN, TOKEN, REDIRECT_URL } from "constants/api";
import {
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { LoginCredentials, LoginResponse, MeResponse } from "types/login";
import { User, UserStatus } from "types/user";
import { isSuccessStatus } from "utils/is-success-status";
import { ROUTES } from "constants/routes";
import { ImpersonateResponse, useImpersonateSpecialist } from "hooks/api/admin/use-impersonate-specialist";
import { isSuccessResponse } from "utils/is-success-response";

interface AuthProviderProps extends PropsWithChildren {}

export interface AuthState {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  loginSpecialist: (credentials: LoginCredentials) => Promise<{ success: boolean }>;
  loginSingleSignOn: (credentials: string) => Promise<{ success: boolean }>;
  login: (credentials: LoginCredentials) => Promise<{ success: boolean }>;
  loginVIISP: (credentials: { token: string; refreshToken: string }) => Promise<{ success: boolean }>;
  logout: () => void;
  isReady: boolean;
  confirmUser: (password: string, token: string) => Promise<{ success: boolean }>;
  getMe: () => Promise<void>;
  impersonate: (personalCode: string) => void;
  endImpersonation: () => void;
  isImpersonated: boolean;
}

const AuthContext = createContext<AuthState>({} as any);

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [isReady, setIsReady] = useState(false);

  const isImpersonated = Boolean(localStorage.getItem("impersonatorToken"));

  const { mutateAsync: impersonateSpecialist } = useImpersonateSpecialist();

  const login: AuthState["login"] = async (data, isSpecialist = false) => {
    const res = await apiClient.post<LoginResponse>(isSpecialist ? "/auth/specialist-user/login" : "/auth/login", data);
    if (res.data.token) {
      const { token, refreshToken } = res.data;
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      await getMe();
      return { success: true };
    }
    return { success: false };
  };

  const loginSpecialist: AuthState["loginSpecialist"] = async (data) => {
    const resSpecialist = await apiClient.post<LoginResponse>("/auth/specialist-user/login", data);
    if (resSpecialist.data.token) {
      const { token, refreshToken } = resSpecialist.data;
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      await getMe();
      return { success: true };
    }
    return { success: false };
  };

  const loginSingleSignOn: AuthState["loginSingleSignOn"] = async (data) => {
    const resSingleSignOn = await apiClient.post<LoginResponse>("/sso-authenticate-user", { ssoSession: data });
    if (resSingleSignOn.data.token) {
      const { token, refreshToken } = resSingleSignOn.data;
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      await getMe();
      return { success: true };
    }
    return { success: false };
  };

  const loginVIISP: AuthState["loginVIISP"] = async (data) => {
    localStorage.setItem(TOKEN, data.token);
    localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
    await getMe();
    return { success: true };
  };

  const logout = () => {
    if (isImpersonated) {
      endImpersonation();
    } else {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(REDIRECT_URL);
      setUser(undefined);
      window.location.href = ROUTES.home;
    }
  };

  const endImpersonation = async () => {
    const impersonatorToken = localStorage.getItem("impersonatorToken");
    if (impersonatorToken) {
      localStorage.setItem(TOKEN, impersonatorToken);
      localStorage.removeItem("impersonatorToken");
      await getMe();
      window.location.reload();
    }
  };

  const getMe = async () => {
    const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";
    if (isMaintenanceMode) {
      return;
    }

    const res = await apiClient.get<MeResponse>("/auth/me");

    if (res.status === 401 || res.status === 403) {
      logout();
      window.location.href = '/';
      return;
    }

    if (res.data.firstName) {
      setUser({
        id: 1,
        status: UserStatus.PENDING,
        healthcareInstitution: null,
        organizerInstitution: null,
        enabledSystemNotifications: true,
        enabledSystemNotificationsEmail: true,
        ...res.data,
      });
    }
  };

  const impersonate = async (personalCode: string) => {
    try {
      const currentToken = localStorage.getItem("token");
      if (currentToken) {
        localStorage.setItem("impersonatorToken", currentToken);
      }

      const response = await impersonateSpecialist(personalCode);
      if (isSuccessResponse<ImpersonateResponse>(response)) {
        localStorage.setItem(TOKEN, response.data.token);
        await getMe();
        window.location.href = ROUTES.home;
      }
    } catch (error) {
      console.error("Failed to impersonate specialist:", error);
      window.location.href = ROUTES.home;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      getMe();
      setIsReady(true);
    }
  }, []);

  const confirmUser: AuthState["confirmUser"] = async (password, token) => {
    const res: LoginResponse | null = await fetch(`${API_URL}/api/admin/users/change-password/${token}`, {
      method: "POST",
      body: JSON.stringify({ password }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (isSuccessStatus(res.status)) {
        return res.json();
      } else return null;
    });

    if (res) {
      localStorage.setItem(TOKEN, res.token);
      localStorage.setItem(REFRESH_TOKEN, res.refreshToken);
      await getMe();
      return { success: true };
    } else {
      return { success: false };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        loginSpecialist,
        loginSingleSignOn,
        loginVIISP,
        logout,
        user,
        setUser,
        isReady,
        confirmUser,
        getMe,
        impersonate,
        endImpersonation,
        isImpersonated
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
