import { Breadcrumbs, Link } from "@mui/material";
import Container from "components/container";
import Layout from "components/layout"
import { useDictionary } from "hooks/use-dictionary";
import SectionTitle from "./home/components/section-title";
import { RichTextContainer } from "components/rich-text-container";
import NKLImage from 'assets/about-project/nkl.png';
import FESNGEUImage from 'assets/about-project/fes-ngeu.png';

export const AboutProject = () => {
  const dict = useDictionary();

  return <Layout>
    <div className="space-y-8 py-12">
      <Container className="bg-theme-background" childClassName="space-y-4">
        <Breadcrumbs>
          <Link href="/">{dict.common.breadcrumbsRoot}</Link>
          <div>{dict.aboutProject.title}</div>
        </Breadcrumbs>
          <div className="space-y-5">
            <SectionTitle>{dict.aboutProject.mainHeading}</SectionTitle>
            <div className="bg-white border rounded p-5 space-y-5">
              <div className="bg-theme-border leading-6 rounded-full px-3 text-xs w-fit">{"2024-12-05"}</div>
              <div className="space-y-2">
                <div className="content-block [&_p]:mt-3">
                  <div className="flex justify-between">
                    <img
                      alt=""
                      height={176}
                      src={FESNGEUImage}
                      width={623}
                    />
                    <span style={{ fontSize: "11.0pt" }}>
                      <span>
                        <img
                          alt=""
                          height={175}
                          src={NKLImage}
                          width={446}
                        />
                      </span>
                    </span>
                  </div>
                  <p>
                    Valstybinė akreditavimo sveikatos priežiūros veiklai tarnyba prie Sveikatos
                    apsaugos ministerijos (toliau – Akreditavimo tarnyba) ir VšĮ Centrinė
                    projektų valdymo agentūra (toliau – administruojančioji institucija) 2022
                    metų gruodžio mėnesį pasirašė projekto „SVEIKATOS PRIEŽIŪROS SPECIALISTŲ
                    KOMPETENCIJŲ PLATFORMOS SUKŪRIMAS“&nbsp;(projekto kodas
                    Nr.&nbsp;09-001-P-0001) finansavimo ir administravimo sutartį.
                  </p>
                  <p>
                    Projekto biudžetas – 2 299 760,10 EUR. Projektas įgyvendinamas su
                    partneriais: VšĮ Vilniaus universitetu, Lietuvos sveikatos mokslų
                    universitetu, Higienos institutu (toliau – HI) ir Valstybine vaistų
                    kontrolės tarnyba prie Lietuvos Respublikos sveikatos apsaugos ministerijos
                    (toliau – VVKT).
                  </p>
                  <p>
                    Projekto tikslas – sukurti Kompetencijų platformą, kurios duomenų pagrindu
                    būtų galima vykdyti sveikatos priežiūros specialistų profesinės
                    kvalifikacijos tobulinimo planavimą, jo vykdymo stebėseną, vertinimą,
                    licencijuojamos veiklos sąlygų laikymosi priežiūrą bei užtikrinti visų
                    Specialistų kompetencijų profesinės kvalifikacijos tobulinimo proceso
                    dalyvių sąveiką.
                  </p>
                  <p>Projekto tikslinės grupės:</p>
                  <p>
                    <strong>Akreditavimo tarnyba</strong>&nbsp;vykdys sveikatos priežiūros
                    specialistų ir įstaigų licencijų išdavimą ir&nbsp; licencijuojamos veiklos
                    sąlygų laikymosi priežiūrą;
                  </p>
                  <p>
                    <strong>VVKT</strong>&nbsp;vykdys farmacijos specialistų licencijų išdavimą
                    ir priežiūrą;
                  </p>
                  <p>
                    <strong>Sveikatos priežiūros specialistai</strong>&nbsp;vertins savo turimas
                    kompetencijas ir sudarys metinį kompetencijų tobulinimo planą,&nbsp; rinksis
                    tobulinimosi programas;
                  </p>
                  <p>
                    <strong>
                      Asmenys, sveikatos apsaugos ministro nustatyta tvarka Kompetencijų
                      platformoje tvirtinantys Specialistų savęs vertinimo anketą ir metinį
                      kompetencijų tobulinimo planą
                    </strong>
                    ,&nbsp; tvirtins Specialistų savęs vertinimo anketą ir metinį kompetencijų
                    tobulinimo planą;
                  </p>
                  <p>
                    <strong>Gydymo įstaigos ir vaistinės</strong>&nbsp;planuos savo įstaigos ir
                    sveikatos priežiūros specialistų profesinių kompetencijų tobulinimo
                    poreikius;
                  </p>
                  <p>
                    <strong>
                      Švietimo įstaigos&nbsp; ir profesinio tobulinimo organizatoriai
                    </strong>
                    &nbsp;teiks derinamų tobulinimo programų duomenis, teiks elektroninių
                    pažymėjimų, išduotų specialistams, dalyvavusiems profesinės kvalifikacijos
                    tobulinimo renginyje ar mokymuose, duomenis;
                  </p>
                  <p>
                    <strong>Savivaldybės</strong>&nbsp;vykdys pavaldžių biudžetinių ir viešųjų
                    gydymo įstaigų specialistų profesinės kvalifikacijos tobulinimo stebėseną;
                  </p>
                  <p>
                    <strong>Sveikatos apsaugos ministerija</strong>&nbsp;planuos specialistų
                    kvalifikacijos tobulinimo prioritetines kryptis;
                  </p>
                  <p>
                    <strong>HI</strong>&nbsp;derins profesinės kvalifikacijos tobulinimo
                    renginius.
                  </p>
                  <p>
                    Projektu siekiama sukurti asmens sveikatos priežiūros kokybės rodiklių
                    analitikos ir vertinimo sistemą, taip pat įgyvendinti technologinius
                    sprendimus, kurie susietų sveikatos specialistų licencijas su privalomu
                    kvalifikacijos tobulinimu, taip efektyviau užtikrinant sveikatos priežiūros
                    ir farmacijos specialistų kompetencijų atnaujinimo stebėseną ir priežiūrą.
                    Sukūrus Kompetencijų platformą, ji būtų integruota su atitinkamomis
                    valstybės informacinėmis sistemomis, registrais.
                  </p>
                  <p>
                    Duomenis apie įvykdytą specialistų profesinės kvalifikacijos tobulinimą
                    švietimo teikėjai ir tobulinimo organizatoriai teiks tiesiogiai į
                    Kompetencijų platformą, joje bus talpinama informacija apie visas suderintas
                    specialistams skirtas mokymo programas.
                  </p>
                  <p>
                    Kompetencijų platformoje bus realizuotas specialistų kompetencijų vertinimo
                    modulis.
                  </p>
                  <p>
                    Duomenys apie profesinės kvalifikacijos tobulinimą bus
                    naudojami&nbsp;kompetencijoms efektyviai valdyti&nbsp;ir nustatyti
                    specialistų ir kompetencijų poreikį, mokymus.
                  </p>
                  <p>
                    Kompetencijų platformos duomenys bus pasitelkiami valdant ekstremalias
                    situacijas, atrenkant koncentruotus reikalingus duomenis apie specialistus,
                    jų kvalifikaciją ir pasiekiamumą.
                  </p>
                  <p>
                    &nbsp;Planuojama, kad sukūrus Kompetencijų platformą naudą gaus ne tik
                    Akreditavimo tarnyba, projekto partneriai, kitos tikslinės grupės, bet ir
                    visi Lietuvos pacientai dėl žymiai paprastesnės, sumažėjusios
                    administracinės naštos sveikatos priežiūros specialistams.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </Container>
    </div>
  </Layout>
}