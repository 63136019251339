import { useDictionary } from "hooks/use-dictionary";
import TopicsComposition from "pages/organizer-admin/event-create/components/topics-composition";
import { FunctionComponent, useMemo } from "react";
import { Event } from "types/event";
import { LearningProgram } from "types/program";
import { interpolate } from "utils/interpolate";

interface EventByLearningProgramViewProps {
  event: Event;
  program: LearningProgram;
}

const EventByLearningProgramView: FunctionComponent<EventByLearningProgramViewProps> = ({ event, program }) => {
  const dict = useDictionary();
  const events = dict.organizerAdmin.events;
  const programs = dict.programs;

  const targetedProgramClassifiers = useMemo(() => {
    return event.program?.targetedProgramClassifiers?.map((c) => c.valueName).join(", ");
  }, [event.program?.targetedProgramClassifiers]);

  const practiceTypeSpecializationCompetencyClassifiers = useMemo(() => {
    return program?.practiceTypeSpecializationCompetencyClassifiers?.map((c) => c.valueName).join(", ");
  }, [program?.practiceTypeSpecializationCompetencyClassifiers]);

  if (!event.program) {
    return null;
  }

  return (
    <div className="space-y-11 p-6">
      <div className="space-y-6">
        <div className="text-2xl font-semibold">{events.mainInformation}</div>
        <div className="space-y-2">
          <div className="font-bold">{events.type}</div>
          <div>{events.eventByLearningProgram}</div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.organizerExecutingProgram}</div>
          <div>{event.organizerInstitution.name}</div>
        </div>
        {event.description && (
          <div className="space-y-2">
            <div className="font-bold">{events.description}</div>
            <div dangerouslySetInnerHTML={{ __html: event.description }} />
          </div>
        )}
        <div className="space-y-2">
          <div className="font-bold">{events.eventLocation}</div>
          <div>{event.place}</div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.website}</div>
          <a className="text-theme-primary underline block" href={`${event.website}`}>
            {event.website}
          </a>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.eventPrice}</div>
          <div>
            {" "}
            {interpolate(dict.organizerAdmin.events.priceValue, {
              value: event.price,
            })}
          </div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.targetPrograms}</div>
          <div>{targetedProgramClassifiers}</div>
        </div>
      </div>
      <div className="space-y-6">
        <div className="text-2xl font-semibold">{events.providedCompetencies}</div>
        <div>{practiceTypeSpecializationCompetencyClassifiers}</div>
      </div>
      <div className="space-y-6">
        <div className="text-2xl font-semibold">{programs.mandatoryTopicsStructure}</div>
        <TopicsComposition topics={event.program.mandatoryTopicClassifiers} />
      </div>
    </div>
  );
};

export default EventByLearningProgramView;
