import { ReactElement, Suspense, lazy, useMemo } from "react";
import { useAuth } from "providers/auth";
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { ROUTES } from "constants/routes";
import LoadingCleanup from "components/loading-cleanup";
import { UserRole } from "types/user";
import SingleSignOnPage from "./public/single-sign-on";
import VIISPIntegration from "./public/viisp";
import ExternalEvaluationFormAnswerPage from "./public/external-evaluation-form-answer";
import ExternalEvaluationFormAnsweredPage from "./public/external-evaluation-form-answer/components/external-evaluation-form-answered";
import { NewsArticlePage } from "./public/news";
import { NewsListPage } from "./public/news/news-list";
import EventsPage from "./public/events/events";
import EventViewPage from "./public/event-view";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "components/error-page";
import { AboutProject } from "./public/about-project";
import { UserGuide } from "./public/user-guide";
import MaintenancePage from "./public/maintenance/maintenance";

const Evaluator = lazy(() => import("./evaluator"));
const SystemAdmin = lazy(() => import("./system-admin"));
const OrganizerAdmin = lazy(() => import("./organizer-admin"));
const OrganizerEmployee = lazy(() => import("./organizer-employee"));
const HygieneSpecialist = lazy(() => import("./hygiene-specialist"));
const ControllingAuthority = lazy(() => import("./controlling-authority"));
const Specialist = lazy(() => import("./specialist"));
const HealthcareEmployee = lazy(() => import("./health-care-employee"));
const HealthcareAdmin = lazy(() => import("./health-care-admin"));
const Analyst = lazy(() => import("./analyst"));
const Home = lazy(() => import("./public/home"));
const NotFound = lazy(() => import("./public/not-found"));
const Login = lazy(() => import("./public/login"));
const Registration = lazy(() => import("./public/registration"));
const QuestionnaireDeveloper = lazy(() => import("./questionnaire-developer"));

const pages: { [key in UserRole]: ReactElement } = {
  ROLE_EVALUATOR_USER: <Evaluator />,
  ROLE_ADMIN: <SystemAdmin />,
  ROLE_ORGANIZER_ADMIN: <OrganizerAdmin />,
  ROLE_ORGANIZER_EMPLOYEE: <OrganizerEmployee />,
  ROLE_HYGIENE_INSTITUTION_USER: <HygieneSpecialist />,
  ROLE_SPECIALIST_USER: <Specialist />,
  ROLE_ANALYST_USER: <Analyst />,
  ROLE_CONTROLLING_AUTHORITY_USER: <ControllingAuthority />,
  ROLE_QUESTIONNAIRE_DEVELOPER_USER: <QuestionnaireDeveloper />,
  ROLE_HEALTHCARE_ADMIN: <HealthcareAdmin />,
  ROLE_HEALTHCARE_EMPLOYEE: <HealthcareEmployee />,
};

const Pages = () => {
  const { user, isReady } = useAuth();
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  const router = useMemo(() => {
    if (isMaintenanceMode) {
      return createBrowserRouter(
        createRoutesFromElements(
          <Route
            element={
              <Suspense>
                <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorPage resetError={resetError} />}>
                  <LoadingCleanup />
                  <Outlet />
                </Sentry.ErrorBoundary>
              </Suspense>
            }
            path="/"
          >
            <Route path="/" element={<MaintenancePage />} />
            <Route path="*" element={<MaintenancePage />} />
          </Route>
        )
      );
    }

    const page = !user ? <div /> : pages[user.role];
    return createBrowserRouter(
      createRoutesFromElements(
        <Route
          element={
            <Suspense>
              <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorPage resetError={resetError} />}>
                <LoadingCleanup />
                <Outlet />
              </Sentry.ErrorBoundary>
            </Suspense>
          }
          path="/"
        >
          <Route element={<Login />} path={ROUTES.login.home + "*"} />
          <Route element={page} path={ROUTES.user + "*"} />
          <Route path="/" element={<Home />} />
          <Route path={ROUTES.newsList} element={<NewsListPage />} />
          <Route path={ROUTES.newsArticleView} element={<NewsArticlePage />} />
          <Route path={ROUTES.registration} element={<Registration />} />
          <Route path={ROUTES.singleSignOn} element={<SingleSignOnPage />} />
          <Route path={ROUTES.viisp} element={<VIISPIntegration />} />
          <Route path={ROUTES.events} element={<EventsPage />} />
          <Route path={ROUTES.eventView} element={<EventViewPage />} />
          <Route path={ROUTES.externalEvaluationFormAnswer.main} element={<ExternalEvaluationFormAnswerPage />} />
          <Route path={ROUTES.externalEvaluationFormAnswer.answered} element={<ExternalEvaluationFormAnsweredPage />} />
          <Route path={ROUTES.aboutProject} element={<AboutProject />} />
          <Route path={ROUTES.userGuide} element={<UserGuide />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      )
    );
  }, [user, isMaintenanceMode]);

  return <RouterProvider router={router} />;
};

export default Pages;
