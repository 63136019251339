import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useDictionary } from "hooks/use-dictionary";
import { useAuth } from "providers/auth";
import Layout from "components/layout";
import { useSnackbar } from "notistack";

type VIISPIntegrationProps = {
  isSuccess: string;
  ssoSessionId: string;
  token: string;
  refreshToken: string;
};

const VIISPIntegration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dict = useDictionary();
  const { loginVIISP } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState(dict.common.loading);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isSuccess = searchParams.get("isSuccess");
    const ssoSessionId = searchParams.get("ssoSessionId");
    const token = searchParams.get("token");
    const refreshToken = searchParams.get("refreshToken");
    const errorMessage = searchParams.get("message");

    if (isSuccess && ssoSessionId && token && refreshToken) {
      authenticateAndSendData({
        isSuccess,
        ssoSessionId,
        token,
        refreshToken,
      });
    } else {
      setMessage(errorMessage || dict.messages.error);
      enqueueSnackbar({
        variant: "baseError",
        message: errorMessage || dict.messages.error,
        errors: [],
      });
    }
  }, []);

  const authenticateAndSendData = async ({ isSuccess, ssoSessionId, token, refreshToken }: VIISPIntegrationProps) => {
    if (isSuccess === "1") {
      loginVIISP({ token, refreshToken });
      navigate(ROUTES.user);
    } else {
      enqueueSnackbar({
        variant: "baseError",
        message: dict.messages.error,
        errors: [],
      });
    }
  };

  return (
    <Layout>
      <div className="flex justify-center items-center h-screen text-2xl">{message}</div>
    </Layout>
  );
};

export default VIISPIntegration;
