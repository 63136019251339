import { Control, Controller, FieldValues, Path } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ComponentProps } from "react";
import { InputAdornment } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";

export interface FormTextFieldProps<T extends FieldValues> extends ComponentProps<typeof TextField> {
  control: Control<T, any>;
  name: Path<T>;
  percentage?: boolean;
  integerOnly?: boolean;
  disabledArrows?: boolean;
  disableZero?: boolean;
}

const FormTextField = <T extends FieldValues>({
  name,
  control,
  onChange,
  helperText,
  percentage = false,
  integerOnly = false,
  disabledArrows = false,
  disableZero = false,
  type,
  ...inputProps
}: FormTextFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { value, onChange: _onChange, ...field },
      fieldState: { error },
      formState: { isSubmitted },
    }) => (
      <TextField
        type={type}
        color="primary"
        helperText={isSubmitted && !!error ? error?.message : helperText}
        error={isSubmitted && !!error}
        value={value ?? ""}
        onWheel={(e) => {
          if (type === "number") {
            (e.target as HTMLElement).blur();
          }
        }}
        onKeyDown={(e) => {
          if (type === "number" && ["e", "-", "+"].includes(e.key.toLowerCase())) {
            e.preventDefault();
          }
          if (integerOnly && type === "number" && e.key === ".") {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          const newValue = e.target.value;
          if (type === "number" && disableZero && newValue === "0") {
            e.target.value = "";
          }
          onChange?.(e);
          _onChange(e);
        }}
        {...field}
        {...inputProps}
        InputProps={{
          endAdornment: percentage ? (
            <InputAdornment position="end" style={{ marginLeft: "-15px", marginRight: "-10px" }}>
              <PercentIcon />
            </InputAdornment>
          ) : undefined,
        }}
        sx={{
          "& input[type=number]": {
            MozAppearance: disabledArrows && type === "number" ? "textfield" : undefined,
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: disabledArrows && type === "number" ? "none" : undefined,
            margin: disabledArrows && type === "number" ? 0 : undefined,
          },
        }}
        ref={null}
      />
    )}
  />
);

export default FormTextField;
