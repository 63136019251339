import { downloadFile } from "utils/files/download-file";

export const QUERY_KEYS = {
  programs: "programs",
  program: "program",
  programVersions: "program-versions",
  events: "events",
  isEvaluated: "is-evaluated",
  adminNotifications: "admin-notifications",
  eventVersions: "event-versions",
  logs: "logs",
  users: "users",
  user: "user",
  programStatus: "program-status",
  healthCareEmployees: "healthCare-employees",
  classifiers: "classifiers",
  publicClassifiers: "public-classifiers",
  classifierValues: "classifier-values",
  professionalQualificationClassifiers: "professional-qualification-classifiers",
  healthCareAdresses: "healthCare-adresses",
  publicClassifierValues: "public-classifier-values",
  systemParameters: "system-parameters",
  systemParameterConfigurations: "system-parameter-configurations",
  notificationsTemplates: "notificationsTemplates",
  notificationRestTemplate: "notificationResetTemplate",
  healthcareInstitutions: "healthcare-institutions",
  organizerInstitutions: "organizer-institutions",
  institutionRegistration: "institution-registration",
  institutionsRegistrations: "institutions-registrations",
  publicOrganizerInstitutions: "public-organizer-institutions",
  institutionEmployees: "institution-employees",
  notifications: "notifications",
  logsParameters: "logs-parameters",
  entrustableProfessionalActivities: "entrustable-professional-activities",
  certificatesOthetAndWithoutDocuments: "certificates-other-and-without-documents",
  certificatesBasedOnProgram: "certificates-based-on-program",
  certificateWithoutDocument: "certificate-without-document",
  certificateBasedOnProgram: "certificate-based-on-program",
  certificateOther: "certificate-other",
  certificate: "certificate",
  residencyStudyPrograms: "residency-study-programs",
  surveys: "surveys",
  respondentSurveys: "respondent-surveys",
  surveyAnswers: "survey-answers",
  licenses: "licenses",
  calculate: "calculate",
  specialist: "specialist",
  specialistSearch: "specialists-search",
  specialisstSearchForSpecialist: "specialist-search-for-specialist",
  specialistCertificiates: "specialist-certificates",
  contacts: "contacts",
  specialistContacts: "specialist-contacts",
  institutionName: "institution-name",
  specialistWorkPlaces: "specialist-work-places",
  professionalActivities: "professional-activities",
  mentors: "mentors",
  mentees: "mentees",
  mentee: "mentee",
  mentor: "mentor",
  developmentPlans: "development-plans",
  mentorQuestionnaires: "mentor-questionnaires",
  menteeDevelopmentPlans: "mentee-development-plans",
  staticContentList: "static-content-list",
  staticContentGet: "static-content-get",
  addresses: "addresses",
  competencies: "competencies",
  diplomas: "diplomas",
  generatedDocuments: "generated-documents",
  generatePdf: "generate-pdf",
  downloadFile: "download-file",
  questionnaires: "questionnaires",
  questionnairesStatistics: "questionnaires-statistics",
  externalEvaluationFormStatus: "externalEvaluationFormStatus",
  menteeUserDevelopmentPlanQuestionnaires: "mentee-users-development-plan-questionnaires",
  menteeUserDevelopmentPlanQuestionnaireAnswers: "mentee-user-development-plan-questionnaire-answers",
  newsList: "news-list",
  newsArticleGet: "news-article-get",
  programDrafts: "program-drafts",
  programStatuses: "program-statuses",
};
