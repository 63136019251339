import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ROUTES } from "constants/routes";
import { generatePath } from "react-router-dom";
import TablePagination from "components/table-pagination";
import Link from "components/link";
import { useNavigate } from "hooks/use-navigate";
import { useDictionary } from "hooks/use-dictionary";
import { usePublicEvents } from "hooks/api/use-public-events";
import Layout from "components/layout";
import { Container } from "@mui/material";
import FiltersBar from "./components/filters-bar";
import EventCard from "components/event-card";
import FiltersAlert from "./components/filters-alerts";
import { PublicEventsFilters } from "types/event";
import EmptyState from "components/empty-state";
import { useEffect } from "react";
import { usePageAndTab } from "hooks/use-page-and-tab";

const EventsPage = () => {
  const navigate = useNavigate();
  const eventsData = usePublicEvents(12);
  const { items, filters, setFilters, tableTop, ...pagination } = eventsData;
  const dict = useDictionary();
  const { constructUrlParams } = usePageAndTab();

  useEffect(() => {
    if (pagination.page > pagination.pages) {
      pagination.onPage(1);
    }
  }, [pagination]);

  return (
    <Layout>
      <Container>
        <div className="space-y-6 py-10">
          <Breadcrumbs>
            <Link to={ROUTES.home}>{dict.common.breadcrumbsRoot}</Link>
            <div>{dict.organizerAdmin.events.events}</div>
          </Breadcrumbs>
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold">{dict.organizerAdmin.events.events}</div>
          </div>
          <FiltersBar onSubmit={setFilters} onClear={() => setFilters({})} />
          <FiltersAlert filters={filters as PublicEventsFilters} />
          <div>
            {tableTop}
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
              {items.map((event) => (
                <EventCard
                  event={event}
                  className=" hover:bg-theme-gray100 cursor-pointer"
                  onClick={() =>
                    navigate(ROUTES.home + generatePath(ROUTES.eventView, { id: event.id }) + constructUrlParams())
                  }
                />
              ))}
              {items.length === 0 && (
                <div className="flex flex-col items-center bg-white border py-2 xl:col-span-3 sm:col-span-2">
                  <EmptyState
                    title={dict.common.eventEmptyState.title}
                    text={dict.common.eventEmptyState.description}
                  />
                </div>
              )}
            </div>
          </div>
          <TablePagination className="bg-white rounded-b border px-5 py-2" {...pagination} rowsPerPageOptions={[12]} />
        </div>
      </Container>
    </Layout>
  );
};

export default EventsPage;
