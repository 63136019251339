import { useDictionary } from "hooks/use-dictionary";
import TopicsComposition from "pages/organizer-admin/event-create/components/topics-composition";
import { FunctionComponent, useMemo } from "react";
import { Event } from "types/event";
import { DevelopmentProgram } from "types/program";
import { DevelopmentCompetencyGroup } from "types/program/development-program";
import { interpolate } from "utils/interpolate";

interface EventByDevelopmentProgramViewProps {
  event: Event;
  program: DevelopmentProgram;
}

const EventByDevelopmentProgramView: FunctionComponent<EventByDevelopmentProgramViewProps> = ({ event, program }) => {
  const dict = useDictionary();
  const events = dict.organizerAdmin.events;
  const programs = dict.programs;

  const targetedProgramClassifiers = useMemo(() => {
    return event.program?.targetedProgramClassifiers?.map((c) => c.valueName).join(", ");
  }, [event.program?.targetedProgramClassifiers]);

  const classifierComponents = useMemo(() => {
    return program?.developmentCompetencyGroupClassifiers?.map((classifier) => {
      let headingText;
      if (classifier.classifier.valueName === DevelopmentCompetencyGroup.CLINICAL_SPECIALTIES) {
        headingText = dict.programs.hoursForCompetenceClinic;
      } else if (classifier.classifier.valueName === DevelopmentCompetencyGroup.GENERAL_CLINICAL_SPECIALTIES) {
        headingText = dict.programs.hoursForCompetenceSpecial;
      } else if (classifier.classifier.valueName === DevelopmentCompetencyGroup.GENERAL_SPECIALTIES) {
        headingText = dict.programs.hoursForCompetenceNotClinic;
      }

      const targetedClassifierText =
        classifier.classifier.valueName === DevelopmentCompetencyGroup.CLINICAL_SPECIALTIES
          ? events.targetedClassifiers
          : null;

      const targetedAudiences =
        classifier.classifier.valueName === DevelopmentCompetencyGroup.CLINICAL_SPECIALTIES
          ? program.targetedAudienceClassifiers?.map((c) => c.valueName).join(", ")
          : null;

      return (
        <div key={classifier.classifier.id} className="grid grid-cols-2">
          <div className="py-2">
            {headingText && <div className="font-bold mb-1">{headingText}</div>}
            <div>
              {interpolate(dict.organizerAdmin.events.durationValue, {
                value: classifier.duration,
              })}
            </div>
          </div>
          {targetedClassifierText && (
            <div className="py-2">
              <div className="font-bold mb-1">{targetedClassifierText}</div>
              <div>{targetedAudiences}</div>
            </div>
          )}
        </div>
      );
    });
  }, [event.program, dict, events.targetedClassifiers]);

  if (!event.program) {
    return null;
  }

  return (
    <div className="space-y-11 p-6">
      <div className="space-y-6">
        <div className="text-2xl font-semibold">{events.mainInformation}</div>
        <div className="space-y-2">
          <div className="font-bold">{events.eventForm}</div>
          <div>{event.programFormValueName || "-"}</div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.type}</div>
          <div>{events.eventByDevelopmentProgram}</div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.organizerExecutingProgram}</div>
          <div>{event.organizerInstitution?.name}</div>
        </div>
        {event.description && (
          <div className="space-y-2">
            <div className="font-bold">{events.description}</div>
            <div dangerouslySetInnerHTML={{ __html: event.description }} />
          </div>
        )}
        {event.place && (
          <div className="space-y-2">
            <div className="font-bold">{events.eventLocation}</div>
            <div>{event.place}</div>
          </div>
        )}
        <div className="space-y-2">
          <div className="font-bold">{events.website}</div>
          <a className="text-theme-primary underline block" href={`${event.website}`}>
            {event.website}
          </a>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.eventPrice}</div>
          <div>
            {interpolate(dict.organizerAdmin.events.priceValue, {
              value: event.price,
            })}
          </div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.targetPrograms}</div>
          <div>{targetedProgramClassifiers}</div>
        </div>
        <div className="space-y-2">
          <div className="font-bold">{events.international}</div>
          <div>{event.international ? dict.common.yes : dict.common.no}</div>
        </div>
      </div>
      <div>
        <div className="font-bold text-2xl mb-2">{programs.hoursForCompetenceGroups}</div>
        {classifierComponents}
      </div>
      <div className="space-y-6">
        <div className="text-2xl font-semibold">{programs.mandatoryTopicsStructure}</div>
        <TopicsComposition topics={event.program.mandatoryTopicClassifiers} />
      </div>
    </div>
  );
};

export default EventByDevelopmentProgramView;
