import { Breadcrumbs, Link } from "@mui/material";
import Container from "components/container";
import Layout from "components/layout";
import { useDictionary } from "hooks/use-dictionary";
import SectionTitle from "./home/components/section-title";
import NKLImage from "assets/about-project/nkl.png";
import FESNGEUImage from "assets/about-project/fes-ngeu.png";
import { ROUTES } from "constants/routes";

export const UserGuide = () => {
  const dict = useDictionary();

  return (
    <Layout>
      <div className="space-y-8 py-12">
        <Container className="bg-theme-background" childClassName="space-y-4">
          <Breadcrumbs>
            <Link href="/">{dict.common.breadcrumbsRoot}</Link>
            <div>{dict.header.help}</div>
          </Breadcrumbs>
          <div className="space-y-5">
            <SectionTitle>{dict.aboutProject.mainHeading}</SectionTitle>
            <div className="bg-white border rounded p-5 space-y-5">
              <div className="space-y-2">
                <div className="content-block [&_p]:mt-3">
                  <p className="text-2xl font-bold text-blue-900">{dict.userGuide.userGuideText1}</p>
                  <p className="text-xl font-bold py-4">{dict.userGuide.userGuideText2}</p>
                  <ul className="list-disc ml-5 text-blue-500">
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://vaspvt.lrv.lt/public/canonical/1734486243/8111/Higienos%20instituto%20specialistų%20naudojimosi%20vadovas.pdf"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText3}
                      </a>
                    </li>
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://vaspvt.lrv.lt/public/canonical/1734483775/8106/Vertintojų%20naudojimosi%20vadovas.pdf"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText4}
                      </a>
                    </li>
                    <li className="text-xl text-black">{dict.userGuide.userGuideText5}</li>
                  </ul>

                  <p className="text-xl font-bold py-4">{dict.userGuide.userGuideText6}</p>
                  <ul className="list-disc ml-5 text-blue-500">
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://vaspvt.lrv.lt/public/canonical/1734486332/8113/Sveikatos%20priežiūros%20įstaigos%20darbuotojų%20ir%20administratorių%20naudojimosi%20vadovas.pdf"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText7}
                      </a>
                    </li>
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/watch?v=hRTYyFFF3zU"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText8}
                      </a>
                    </li>
                  </ul>

                  <p className="text-xl font-bold py-4">{dict.userGuide.userGuideText9}</p>
                  <ul className="list-disc ml-5 text-blue-500">
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://vaspvt.lrv.lt/public/canonical/1734486357/8114/Tobulinimo%20organizavimo%20įstaigos%20darbuotojų%20ir%20administratorių%20naudojimosi%20vadovas.pdf"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText10}
                      </a>
                    </li>
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/watch?v=1i0HHqkIGLw"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText8}
                      </a>
                    </li>
                  </ul>

                  <p className="text-xl font-bold py-4">{dict.userGuide.userGuideText11}</p>
                  <ul className="list-disc ml-5 text-blue-500">
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://vaspvt.lrv.lt/public/canonical/1734486380/8115/Specialisto%20naudojimosi%20vadovas.pdf"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText12}
                      </a>
                    </li>
                    <li className="text-xl">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/watch?v=C5ey9fCBOlk&feature=youtu.be"
                        className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                      >
                        {dict.userGuide.userGuideText8}
                      </a>
                    </li>
                  </ul>
                  <p className="text-xl font-bold py-4">
                    {dict.userGuide.userGuideText13}{" "}
                    <a
                      target="_blank"
                      href={ROUTES.login.home + ROUTES.registerInstitutionRepresentative}
                      className="text-blue-500 hover:text-black hover:underline hover:cursor-pointer"
                    >
                      {dict.userGuide.userGuideText14}
                    </a>
                  </p>
                  <p className="text-xl font-bold py-4">{dict.userGuide.userGuideText15}</p>
                  <div className="flex items-center space-x-2">
                    <div className="text-lg" dangerouslySetInnerHTML={{ __html: dict.userGuide.userGuideText16 }}></div>
                    <a
                      href="mailto:kompetencijuplatforma@vaspvt.gov.lt"
                      className="text-blue-500 hover:text-black underline text-lg font-bold hover:cursor-pointer"
                    >
                      {dict.userGuide.userGuideText17}
                    </a>
                  </div>

                  <p className="text-lg">{dict.userGuide.userGuideText18}</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};
